<template>
  <div class="page">
    <form autocomplete="off" @submit="submitForm">
      <div class="field">
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <v-text-field
            label="Email"
            placeholder="Insira seu email"
            outlined
            v-model="email"
            name="email"
            @click="(e) => selecionarcampo(e)"
            :error-messages="erros.email"
          ></v-text-field>
        </v-col>
      </div>
      
      <div class="field">
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <button type="submit">Recuperar</button>
        </v-col>
      </div>
      <div class="field">
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <p style="text-align: start;font-size: 12px;margin-top: 1em">Lembrou sua senha? <a @click="$router.push('/login')">Click aqui.</a></p>
        </v-col>
      </div>
    </form>
  </div>
</template>

<script>
import {alertar} from '@/services/notificacao.service.js'
import AuthServico from '@/services/autenticacao.service.js'
export default {
  data(){
    return{
      email: null,
      erros: {
        email: ''
      }
    }
  },
  methods: {
    submitForm(e){
      e.preventDefault()
      if(this.validacao()){
        const dadosusuario = {
          email: this.email
        }
        AuthServico.recuperarSenha(dadosusuario)
          .then(res => {
            if(res.status === 200){
              this.email = null
              alertar('success', '=)', 'Acesso seu email e confira  link enviado.')
            } 
          })
          .catch(error => {
            console.log('error', error)
          })
      }
    },
    validacao(){
      let validado = true
      if(!this.email || this.email.trim() === ''){
        validado = false
        this.erros.email = 'O campo e-mail é obrigatório.'
      }
      if(!this.email.includes('@')){
        validado = false
        this.erros.email = 'Insira um e-mail válido.'
      }
      return validado
    },
    selecionarcampo(e){
      if(this.erros[e.target.getAttribute('name')]){
        this.erros[e.target.getAttribute('name')] = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page{
  height: 100vh;
  width: 100vw;
  display: grid;
  place-items: center;
}
form{
  margin: 0 auto 5em auto;
  width: 900px;
  max-width: 80vw;
  display: flex;
  flex-direction: column;
  padding: 3em 2em;
  border-radius: 50px;
  background: #f9f9fe;
  box-shadow:  4px 4px 10px #d4d4d8;
}

.field{
  display: flex;
  align-items: center;
  justify-content: center;
}

button[type="submit"]{
  background-color: #1377F2;
  width: 100%;
  height: 100%;
  color: whitesmoke;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  padding: .75em 1em;
  border-radius: 10px;
  font-weight: bold;
}

//css indesejados
.col-12, .col-md-6, .col-sm-6{
  padding: unset;
}
</style>